<template>
    <div class="landing-page-desktop">
        <div>
            <div class="custom-toolbar">
                <div class="toolbar-left" style="display: flex; align-items: center">
                    <img :src="pia_logo_white" alt="Logo"
                        style="height: 40px; margin-right: 10px; position: fixed; top: 10px; left: 15px" />
                    <h2>Hi, {{ userProfile.name }}</h2>
                    <p style="font-size: small; font-weight: bold">{{ moment(date).format('ddd, DD MMMM YYYY') }}</p>
                    <!-- Animated button coming from the left -->
                    <div class="animated-button-container">
                        <AnimatedButton />
                    </div>
                </div>

                <div class="toolbar-right" style="display: flex; justify-content: flex-end; align-items: center">
                    <v-icon class="logout-icon" @click="doLogout"
                        style="font-size: 24px; cursor: pointer; color: white; position: fixed; top: 20px; right: 11px">mdi-logout</v-icon>
                    <div class="weather-location-container mr-5">
                        <div class="weather-display">
                            <div class="weather-row">
                                <!-- Temperature -->
                                <div class="weather-item">
                                    <div class="weather-icon-container">
                                        <div class="weather-icon-background">
                                            <v-icon class="weather-icon">mdi-thermometer</v-icon>
                                        </div>
                                        <div class="weather-text-container">
                                            <p class="weather-text">{{ weatherTemp }}°C</p>
                                            <p class="weather-desc">{{ $t('landing-page.clouds') }}</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- Humidity -->
                                <div class="weather-item mr-5">
                                    <div class="weather-icon-container">
                                        <div class="weather-icon-background">
                                            <v-icon class="weather-icon">mdi-water-percent</v-icon>
                                        </div>
                                        <div class="weather-text-container">
                                            <p class="weather-text">{{ weatherHumidity }}%</p>
                                            <p class="weather-desc">{{ $t('landing-page.humidity') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="weather-row">
                                <!-- Wind Speed -->
                                <div class="weather-item">
                                    <div class="weather-icon-container">
                                        <div class="weather-icon-background">
                                            <v-icon class="weather-icon">mdi-weather-windy</v-icon>
                                        </div>
                                        <div class="weather-text-container">
                                            <p class="weather-text">{{ weatherWind }} m/s</p>
                                            <p class="weather-desc">{{ $t('landing-page.wind') }}</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- Rain Chance -->
                                <div class="weather-item">
                                    <div class="weather-icon-container">
                                        <div class="weather-icon-background">
                                            <v-icon class="weather-icon">mdi-weather-pouring</v-icon>
                                        </div>
                                        <div class="weather-text-container">
                                            <p class="weather-text">{{ weatherRain }}%</p>
                                            <p class="weather-desc">{{ $t('landing-page.rain') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vertical-line"></div>
                        <div class="location-time">
                            <p class="location-text">{{ $t('landing-page.location') }}</p>
                            <p class="location">Bamberg</p>
                            <p class="time">{{ currentTime }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="available-functions-container">
                <div class="available-functions">
                    <p class="heading">{{ $t('landing-page.functions') }}</p>
                    <p class="description">{{ $t('landing-page.functions-description') }}</p>
                </div>

                <div class="card-grid">
                    <div class="card">
                        <div class="icon-background">
                            <v-icon class="card-icon">mdi-desktop-mac</v-icon>
                        </div>
                        <div class="info">
                            <h4>{{ $t('landing-page.desks-available') }}</h4>
                            <p>{{ availableDesks }} desks free</p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="icon-background">
                            <v-icon class="card-icon">mdi-account-group</v-icon>
                        </div>
                        <div class="info">
                            <h4>{{ $t('landing-page.colleagues') }}</h4>
                            <p>{{ colleaguesPresent }} present today</p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="icon-background">
                            <v-icon class="card-icon">mdi-calendar-check</v-icon>
                        </div>
                        <div class="info">
                            <h4>{{ $t('landing-page.attendance') }}</h4>
                            <p>{{ attendanceCount }} days this month</p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="icon-background" style="background-color: #eaf2fe">
                            <v-icon style="color: #4578f4" class="card-icon">mdi-flash-outline</v-icon>
                        </div>
                        <div class="info">
                            <h4 style="color: #4578f4">{{ $t('landing-page.quick-actions') }}</h4>
                            <p> {{ $t('landing-page.quick-actions-description') }}
                                <span style="color: #4578f4; cursor: pointer; font-weight: bold">&nbsp;></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-container">
            <p class="footer-text">{{ $t('landing-page.pinestack-rights') }}</p>
            <div class="footer-links">
                <a href="https://pinestack.io/en/contact/" class="footer-link" target="_blank">{{
                    $t('landing-page.contact-us') }}</a>
                <a href="https://pinestack.io/en/privacy-policy/" class="footer-link" target="_blank">{{
                    $t('landing-page.privacy-policy') }}</a>
            </div>
        </div>
    </div>
</template>

<script>
//import AnalogClock from '@/components/general/ChildComponents/AnalogClock.vue'
import AnimatedButton from '@/components/general/ChildComponents/AnimatedButton.vue'
import { defineComponent, ref, onMounted } from 'vue'
import { useOAuthService } from '@/services/OAuthService'
import moment from 'moment'
import pia_logo_white from '@/assets/Branding/Pia/pia_logo_white.png'
import { doLogout } from '@/controllers/BackboneAPI'

export default defineComponent({
    name: 'LandingPageDesktop',
    components: { AnimatedButton },
    setup() {
        const availableDesks = ref(0)
        const isMobileView = ref(window.innerWidth <= 480)
        const showCards = ref(false) // Control card animation
        const colleaguesPresent = ref(0)
        const attendanceCount = ref(0)
        const weatherTemp = ref(0)
        const weatherDesc = ref('')
        const weatherHumidity = ref(0)
        const weatherWind = ref(0)
        const weatherRain = ref(0)
        const userProfile = ref({ name: 'User' })
        const apiKey = '4ae793a3dd93a074ff16d7e97b06ec5b'
        const city = 'Munich'
        const currentTime = ref(moment().format('HH:mm:ss'))

        const buttons = ref([
            { icon: '🖥️', title: 'Desks Available', description: `${availableDesks.value} desks free` },
            { icon: '👥', title: 'Colleagues Present', description: `${colleaguesPresent.value} present today` },
            { icon: '📅', title: 'Your Attendance', description: `${attendanceCount.value} days this month` },
            { icon: '⚡', title: 'Quick Action', description: 'Book a desk or chat' },
        ])

        const handleResize = () => {
            isMobileView.value = window.innerWidth <= 480
        }

        window.addEventListener('resize', handleResize)

        async function fetchHomeData() {
            try {
                availableDesks.value = 5
                colleaguesPresent.value = 12
                attendanceCount.value = 8
            } catch (error) {
                console.log(error)
            }
        }

        onMounted(async () => {
            try {
                userProfile.value = await useOAuthService().getUserProfile()
                await fetchWeather()
                await fetchHomeData()
            } catch (error) {
                console.log(error)
                showCards.value = true
            } finally {
                showCards.value = true
            }
        })

        async function fetchWeather() {
            const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${apiKey}&units=metric`)
            const data = await response.json()
            weatherTemp.value = data.main.temp.toFixed(1)
            weatherDesc.value = data.weather[0].description
            weatherHumidity.value = data.main.humidity
            weatherWind.value = data.wind.speed
            weatherRain.value = data.rain ? data.rain['1h'] : 0
        }

        setInterval(() => {
            currentTime.value = moment().format('HH:mm:ss')
        }, 1000)

        return {
            availableDesks,
            colleaguesPresent,
            attendanceCount,
            weatherTemp,
            weatherDesc,
            weatherHumidity,
            weatherWind,
            weatherRain,
            userProfile,
            buttons,
            isMobileView,
            moment,
            showCards,
            fetchWeather,
            currentTime,
            pia_logo_white,
            doLogout,
        }
    },
})
</script>

<style scoped>
.landing-page-desktop {
    /* Add your styles here */
}

/* Custom toolbar with improved layout */
.custom-toolbar {
    background-image: url('@/assets/General/landingpage_desktop.png');
    background-size: cover;
    /* Ensures the image covers the area */
    background-position: center;
    /* Centers the image */
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
    opacity: 0;
    height: 24rem;
    animation: fadeIn 1s ease-in-out forwards;
}

@supports (-webkit-touch-callout: none) {
    .custom-toolbar {
        position: sticky;
        top: 0;
        z-index: 1000;
    }
}

.toolbar-left {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    margin-left: 5rem;
}

.toolbar-left h2 {
    font-size: 1.8rem;
    margin-bottom: 5px;
    font-weight: bold;
    color: #ffffff;
}

.toolbar-left p {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #ffffff;
}

.toolbar-right {
    display: flex;
    /* align-items: center; */
    padding: 20px;
}

.weather-location-container {
    display: flex;
    align-items: center;
    width: 38rem;
    background-color: rgba(255, 255, 255, 0.1);
    /* More transparent background */
    backdrop-filter: blur(8px);
    /* Adds a soft blur effect */
    padding: 20px;
    border-radius: 12px;
}

.weather-display {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.weather-row {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    margin-top: 20px;
}

.weather-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

/* Icon and Text Layout */
.weather-icon-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.weather-icon-background {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    /* Rectangular shape with slight rounded corners */
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(8px);
    /* Default icon background color */
}

.weather-icon {
    font-size: 24px;
    color: white;
}

.weather-text-container {
    display: flex;
    flex-direction: column;
}

.weather-text {
    font-size: 26px;
    font-weight: bold;
    color: white;
    margin: 5px 0;
}

.weather-desc {
    font-size: 14px;
    font-weight: bold;
    color: white;
    opacity: 0.7;
}

.vertical-line {
    width: 1.2px;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(8px);
    height: 60%;
    margin: 0 18px;
    position: fixed;
    right: 22%;
}

.location-time {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.location-text,
.location,
.time {
    margin-bottom: 5px;
}

.location-text {
    font-size: 20px;
    font-weight: bold;
}

.time {
    font-size: 30px;
    font-weight: bold;
}

/* Sliding in button animation */
.animated-button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 25px;
    animation: slideInFromLeft 1s ease-in-out forwards;
}

@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

/* Available Functions Container */
.available-functions-container {
    width: 100%;
    max-width: 950px;
    margin: auto;
    text-align: left;
}

/* Available Functions Heading and Description */
.available-functions .heading {
    font-weight: bold;
    font-size: 1.5rem;
    color: #2c3e50;
    margin-bottom: 5px;
}

.available-functions .description {
    color: #718096;
    font-size: 1rem;
    margin-bottom: 35px;
}

/* Card grid layout */
.card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    animation: fadeIn 1s ease-in-out;
}

/* Individual Cards */
.card {
    background-color: #ffffff;
    color: #333;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    text-align: left;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    opacity: 0;
    transform: scale(0.9);
    animation: zoomIn 0.8s ease-in-out forwards;
    gap: 15px;
}

.card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Icon Background */
.icon-background {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: #f3f4f6;
}

.card-icon {
    font-size: 24px;
    color: #333;
}

/* Info Section */
.info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.info h4 {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
    color: #2c3e50;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.info p {
    font-size: 0.8rem;
    color: #7f8c8d;
    margin: 4px 0 0;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f0f0f0;
    color: #718096;
    font-size: 0.875rem;
    border-top: 1px solid #e0e0e0;
    position: fixed;
    bottom: 0px;
    width: 100%;
}

.footer-text {
    margin: 0;
}

.footer-links {
    display: flex;
    gap: 20px;
}

.footer-link {
    color: #718096;
    text-decoration: none;
}

.footer-link:hover {
    text-decoration: underline;
}

/* Zoom in animation for cards */
@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale(0.8);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Fade in animation for toolbar */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Responsive Design */

@media (max-width: 1200px) {
    .weather-location-container {
        width: 28rem;
    }

    .toolbar-left {
        margin-left: 2.4rem;
    }

    .toolbar-left h2 {
        font-size: 1.6rem;
    }

    .toolbar-left p {
        font-size: 1rem;
    }

    .weather-text {
        font-size: 21px;
    }

    .weather-desc {
        font-size: 11px;
    }

    .vertical-line {
        width: 1.2px;
        height: 62%;
        margin: 0 18px;
        position: fixed;
        left: 63.6%;
        background-color: rgba(255, 255, 255, 0.3);
    }

    .available-functions .heading {
        font-size: 1.4rem;
        margin-bottom: 10px;
    }

    .available-functions .description {
        font-size: 0.9rem;
        margin-bottom: 30px;
    }

    .card-grid {
        margin-left: 20px;
        margin-right: 20px;
    }

    .card {
        padding: 15px;
    }

    .info h4 {
        font-size: 1rem;
    }

    .info p {
        font-size: 0.8rem;
    }

    .card-icon {
        font-size: 22px;
    }

    .footer-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4.5px;
        background-color: #f0f0f0;
        color: #718096;
        font-size: 0.7rem;
        border-top: 1px solid #e0e0e0;
        position: fixed;
        bottom: 0px;
        width: 100%;
    }

    .footer-text {
        margin: 0;
    }

    .footer-links {
        display: flex;
        gap: 10px;
    }

    .footer-link {
        color: #718096;
        text-decoration: none;
    }

    .footer-link:hover {
        text-decoration: underline;
    }
}

@media (max-width: 992px) {
    .custom-toolbar {
        flex-direction: row;
        /* Keep toolbar layout side by side */
        justify-content: space-between;
        /* Space between left and right sections */
        height: auto;
        padding: 10px;
    }

    .toolbar-left {
        margin-left: 0;
        align-items: center;
        text-align: left;
        /* Align text to the left */
        flex: 1;
        /* Allow left section to take available space */
        display: flex;
        flex-direction: column;
        /* gap: 10px; */
    }

    .toolbar-left h2 {
        font-size: 1.5rem;
        margin-bottom: 5px;
    }

    .toolbar-left p {
        font-size: 1rem;
    }

    .weather-location-container {
        flex-direction: row;
        /* Set to row for horizontal alignment */
        width: auto;
        padding: 15px;
        display: flex;
        /* Maintain flex display */
        gap: 10px;
        /* Space between weather items */
    }

    .weather-row {
        flex-direction: row;
        /* Ensure weather items are aligned horizontally */
        gap: 15px;
        /* Increase space between weather items */
    }

    .weather-text {
        font-size: 22px;
    }

    .weather-desc {
        font-size: 12px;
    }

    .available-functions .heading {
        font-size: 1.2rem;
        text-align: center;
    }

    .available-functions .description {
        text-align: center;
        font-size: 0.9rem;
    }

    .card-grid {
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        gap: 15px;
        margin: 0 15px;
    }

    .card {
        padding: 15px;
    }

    .card-icon {
        font-size: 20px;
    }

    .info h4 {
        font-size: 0.9rem;
    }

    .info p {
        font-size: 0.7rem;
    }

    /* Vertical Line Adjustment for Smaller Screens */
    .vertical-line {
        width: 1.2px;
        height: 60%;
        /* Make the line height smaller for mobile */
        margin: 0 18px;
        position: fixed;
        left: 64.5%;
        background-color: rgba(255, 255, 255, 0.3);
    }
}

@media (max-width: 768px) {
    .weather-location-container {
        flex-direction: column;
        padding: 10px;
    }

    .weather-row {
        flex-direction: row;
        /* Ensure weather items are aligned horizontally */
        gap: 15px;
        /* Increase space between weather items */
    }

    .weather-text {
        font-size: 16px;
    }

    .weather-desc {
        font-size: 11px;
    }

    .weather-icon-background {
        width: 40px;
        height: 40px;
    }

    .vertical-line {
        display: none;
    }

    .location-time {
        order: -1;
    }

    .available-functions .heading {
        font-size: 1.2rem;
        margin-bottom: 5px;
    }

    .available-functions .description {
        font-size: 0.8rem;
        margin-bottom: 25px;
    }

    .card-grid {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        gap: 15px;
    }

    .card {
        padding: 15px;
        gap: 10px;
    }

    .icon-background {
        width: 50px;
        height: 50px;
    }

    .card-icon {
        font-size: 20px;
    }

    .info h4 {
        font-size: 0.9rem;
    }

    .info p {
        font-size: 0.7rem;
    }

    .toolbar-left h2 {
        font-size: 1.4rem;
    }

    .toolbar-left p {
        font-size: 1rem;
    }

    .footer-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.7px;
        background-color: #f0f0f0;
        color: #718096;
        font-size: 0.8rem;
        border-top: 1px solid #e0e0e0;
        position: fixed;
        bottom: 0px;
        width: 100%;
        /* margin-top: 1rem; */
    }

    .footer-text {
        margin: 0;
    }

    .footer-links {
        display: flex;
        gap: 10px;
    }

    .footer-link {
        color: #718096;
        text-decoration: none;
    }

    .footer-link:hover {
        text-decoration: underline;
    }

}

@media (max-width: 576px) {
    .custom-toolbar {
        padding: 10px;
    }

    .location-text {
        font-size: 15px;
        font-weight: bold;
    }

    .time {
        font-size: 25px;
        font-weight: bold;
    }

    .card-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;
    }

    .card {
        padding: 8px;
        gap: 8px;
    }

    .toolbar-left h2 {
        font-size: 1rem;
    }

    .toolbar-left p {
        font-size: 0.9rem;
    }

    .weather-location-container {
        padding: 5px;
    }

    .weather-item {
        flex-direction: column;
        align-items: center;
    }

    .weather-text {
        font-size: 15px;
    }

    .weather-desc {
        font-size: 10px;
    }

    .info h4 {
        font-size: 0.9rem;
    }

    .info p {
        font-size: 0.7rem;
    }

    /* .vertical-line {
        width: 80%;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(8px);
        position: fixed;
    } */

    .footer-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.7px;
        background-color: #f0f0f0;
        color: #718096;
        font-size: 0.8rem;
        border-top: 1px solid #e0e0e0;
        position: fixed;
        bottom: 0px;
        width: 100%;
    }

    .footer-text {
        margin: 0;
    }

    .footer-links {
        display: flex;
        gap: 10px;
    }

    .footer-link {
        color: #718096;
        text-decoration: none;
    }

    .footer-link:hover {
        text-decoration: underline;
    }

}



@media (max-width: 522px) {
    .weather-location-container {
        flex-direction: column;
        padding: 10px;
        width: 100%;
        align-items: center;
        gap: 10px;
    }

    .weather-row {
        display: flex;
        justify-content: space-between;
        gap: 5px;
        width: 100%;
        max-width: 300px;
    }

    .weather-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }

    .weather-icon-background {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(8px);
    }

    .weather-icon {
        font-size: 16px;
    }

    .weather-text {
        font-size: 12px;
    }

    .weather-desc {
        font-size: 10px;
        opacity: 0.8;
    }

    .vertical-line {
        width: 80%;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.3);
        margin: 10px 0;
    }

    .card-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        padding: 10px;
    }

    .card {
        padding: 10px;
        gap: 5px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .icon-background {
        width: 40px;
        height: 40px;
    }

    .card-icon {
        font-size: 16px;
    }

    .info h4 {
        font-size: 0.9rem;
    }

    .info p {
        font-size: 0.7rem;
    }

    .available-functions-container {
        margin-top: 0;
        padding: 10px;
    }

    .footer-container {
        position: relative;
        padding: 10px;
        margin-top: 15px;
        text-align: center;
    }

    .footer-text {
        font-size: 0.7rem;
    }

    .footer-links {
        flex-direction: column;
        gap: 5px;
        font-size: 0.7rem;
    }
}

@media (max-width: 481px) {
    .weather-location-container {
        padding: 8px;
        gap: 8px;
    }

    .weather-icon-background {
        width: 28px;
        height: 28px;
    }

    .weather-icon {
        font-size: 14px;
    }

    .weather-text {
        font-size: 10px;
    }

    .card {
        padding: 8px;
    }

    .card-icon {
        font-size: 14px;
    }

    .footer-container {
        padding: 4px;
    }
}
</style>
